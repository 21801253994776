<template>
  <div class="main">
    <div class="headerimg">
      <img
        src="../../assets/images/headerprofileimg.svg"
        alt=""
        style="border-bottom: 1px solid rgb(240, 237, 237);"
      >
    </div>
    <div class="picture">
      <div style="margin-bottom: 0px; padding-right: 20px !important; color: black;display: flex; align-items: center;gap: 10px">
        <div v-if="getLoggedInUser.gender == 'Male'">
          <img
            v-if="getLoggedInUser.url == '' || getLoggedInUser.url == null"
            src="@/assets/images/avatarMale.jpg"
            width="160px"
            height="160px"
            alt="test"
            style="margin-right: 5px;border-radius: 50%;"
          >
          <img
            v-else
            v-b-tooltip.hover
            :title="$t('ClickToSeeImage')"
            :src="getLoggedInUser.url"
            width="160px"
            height="160px"
            alt="test"
            style="margin-right: 5px;border-radius: 50%;cursor: pointer;"
            @click="getAllImages(getLoggedInUser.url)"
          >
        </div>
        <div v-else>
          <img
            v-if="getLoggedInUser.url == '' || getLoggedInUser.url == null"
            src="@/assets/images/avatarFemale.svg"
            width="160px"
            height="160px"
            alt="test"
            style="margin-right: 5px;border-radius: 50%;"
          >
          <img
            v-else
            :src="getLoggedInUser.url"
            width="160px"
            height="160px"
            alt="test"
            style="margin-right: 5px;border-radius: 50%;"
            @click="getAllImages(getLoggedInUser.url)"
          >
        </div>
        <span style="display: flex;flex-direction: column;">
          <strong id="name">
            {{ getLoggedInUser.firstName }} {{ getLoggedInUser.lastName }}
          </strong>
        </span>
      </div>
      <button
        v-b-modal.add-image
        class="button"
      >
        {{ $t('AddImage') }}
      </button>
    </div>
    <div class="container">
      <div>
        <b>{{ $t('PersonalInformation') }}</b>
      </div>
      <div class="form">
        <div class="rowInfos">
          <div>
            <label for="name">{{ $t('FirstName') }}</label>
            <p>
              {{ getLoggedInUser.firstName }}
            </p>
          </div>
          <div>
            <label for="lastname">{{ $t('LastN') }}</label>
            <p>
              {{ getLoggedInUser.lastName }}
            </p>
          </div>
        </div>
        <div class="rowInfos">
          <!-- <div>
            <label for="name">{{ $t('Email') }}</label>
            <p>
              admin@digitbusiness.ch
            </p>
          </div> -->
          <div>
            <label for="name">{{ $t('CompanyPosition') }}</label>
            <p>
              {{ getLoggedInUser.role }}
            </p>
          </div>
        </div>
        <div class="logosmall">
          <img
            src="../../assets/images/headerprofileimg.svg"
            alt=""
            style="border: 1px solid rgb(229, 228, 228); border-radius: 8px;"
          >
        </div>
      </div>
    </div>
    <AddImageModal
      @addImage="addImages"
    />
    <vue-easy-lightbox
      :esc-disabled="false"
      :visible="image.visible"
      :imgs="image.imgs"
      :index="image.index"
      @hide="handleHide"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import VueEasyLightbox from 'vue-easy-lightbox'
import AddImageModal from './AddImageModal.vue';

export default {
  components: {
    AddImageModal,
    VueEasyLightbox,
  },
  data() {
    return {
      image: {
        zoom: 1,
        imgs: [],
        visible: false,
        index: 0,
      },
    }
  },
  computed: {
    ...mapGetters(['getLoggedInUser']),
  },
  methods: {
    ...mapActions(['addImageUser']),
    addImages(value) {
      this.getLoggedInUser.url = `https://spim-file-development.s3.amazonaws.com/${value}`
    },
    async getAllImages(item) {
      this.image.imgs = [];

      this.image.imgs.push({ src: item });

      this.show();
    },
    show() {
      this.image.visible = true
    },
    handleHide() {
      this.image.visible = false
      this.image.imgs.length = []
    },
  },
}
</script>

<style scoped>
.main {
  padding: 60px 15px 15px 95px !important;
  display: flex;
  flex-direction: column;
}

.headerimg img {
width: 100%;
}

.picture {
  display:flex;
  align-items:center;
  text-align: left;
  color: #FFFFFF;
  width: 62%;
  position: relative;
  top: -40px;
  left: 400px;
  justify-content: space-between;
}

#name {
  font-size: 30px;
  font-weight: 700;
  color: #101828;
}

.container {
  display: flex;
  width: 70%;
  gap: 5%;
  justify-content: flex-end;
}

.form{
  border: 1px solid #EAECF0;
  width: 55%;
  padding: 24px;
  border-radius: 8px;
}

.rowInfos {
  display: flex;
  gap: 3%;
}

.logosmall img {
  width: 100%;
}

.rowInfos p {
  border-radius: 8px;
  border: 1px solid #D0D5DD;
  width: 300px;
  height: 44px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
}

@media screen and (min-width: 1700px) and (max-width: 1800px) {
  .form{
    border: 1px solid #EAECF0;
    width: 61%;
    padding: 24px;
    border-radius: 8px;
  }
}

@media screen and (min-width: 1500px) and (max-width: 1700px) {
  .form{
    border: 1px solid #EAECF0;
    width: 70%;
    padding: 24px;
    border-radius: 8px;
  }
}

@media screen and (max-width: 1500px) {

  .main {
    padding: 60px 15px 15px 0px !important;
    display: flex;
    flex-direction: column;
  }

  .rowInfos {
    display: flex;
    flex-direction: column;
    gap: 3%;
  }

  .rowInfos p {
    border-radius: 8px;
    border: 1px solid #D0D5DD;
    width: 100%;
    height: 44px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
  }

  .container {
    display: flex;
    width: 100%;
    gap: 5%;
    justify-content: flex-end;
  }

  .picture {
    width: 50%;
    position: relative;
    top: -40px;
    left: 100px;
  }
}
</style>
